<template>
  <div class="regional-associations">
    <div class="heading border-b border-grey-100 py-4">
      <div class="outer-container">
        <div class="inner-container">
          <div class="flex flex-row justify-between items-start mt-8 md:mt-16">
            <div class="section-heading">{{heading}}</div>
            <router-link to="/discover/associations" v-if="showAll" class="cta text-14 flex flex-row items-center uppercase bg-grey-100 text-white py-4 px-6 hover:bg-grey-300 transition-colors">
              View all
              <img src="@/assets/icon-arrow.png" alt="" class="w-8 h-4 block ml-4">
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="outer-container">
      <div class="inner-container">
        <div class="intro-text mt-8" v-if="intro">
          {{ intro }}
        </div>

        <div class="collection-list -mx-4 mt-24">
          <masonry
              :cols="{default: 3, 1023: 2, 767: 1}"
              :gutter="{default: '30px'}"
          >
            <regional-associations-tile  v-for="(item, index) in associations" :key="`association${index}}`" :item="item"/>
          </masonry>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueMasonry from 'vue-masonry-css'
import RegionalAssociationsTile from "@/components/RegionalAssociationsTile";
Vue.use(VueMasonry);
export default {
  name: 'RegionalAssociations',
  components: {RegionalAssociationsTile},
  props: {
    associations: {
      type: Array,
      default () {
        return []
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    },
    heading: {
      type: String,
      default: null
    },
    intro: {
      type: String,
      default: null
    },
    showSpotlight: {
      type: Boolean,
      default: true
    },
    showAll: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.collection-list > div > div:nth-child(even) {
  margin-top: -30px;
}
</style>

<style scoped lang="scss">

.intro-text {
  max-width: 470px;
}
.section-heading {
  font-weight: 600;
  font-size: 24px;

  @media(min-width: 768px)
  {
    font-size: 34px;
  }
}

.collection-tile {
  margin-top: 30px;
}
</style>
