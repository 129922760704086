<template>
  <div>
    <div class="relative">
      <div class="bg"></div>
      <div class="outer-container">
        <div class="inner-container">
          <div class="mt-16">
            <img src="@/assets/our-places.png" alt="" class="md:hidden">
            <state-map />
          </div>
        </div>
      </div>
    </div>
    <div class="collections-section">
      <curated-collections class="mt-16 md:mt-0" :show-all="collectionsCount > 6" :collections="collections" :modular-content="collectionsContent" v-if="collections.length && showCollections === 'true'" intro="Check out our ever-changing curated collections which highlight specific styles, regions and more." heading="Curated collections —" />
    </div>
    <div class="collections-section">
      <regional-associations class="mt-16 md:mt-16" :show-all="false" :associations="associations" :modular-content="associationsContent" v-if="associations.length" heading="Regional associations —" />
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import StateMap from "@/components/StateMap";
import CuratedCollections from "@/components/CuratedCollections";
import Kontent from "@/kontent";
import $ from 'jquery'
import RegionalAssociations from "@/components/RegionalAssociations";
export default {
  name: 'Discover',
  metaInfo () {
    if (!this.page) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {property: 'og:description', content: this.page.elements.page__page_description.value},
      {name: 'title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {name: 'keywords', content: this.page.elements.page__meta_keywords.value},
      {name: 'description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {name: 'twitter:description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value}
    ]

    // if there's an image
    if (this.page.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.page.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.page.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.page.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.page.elements.page__page_type.value[0].name})
    }

    return {
      title: this.page.elements.page__page_title.value,
      meta: meta
    }
  },
  components: {RegionalAssociations, CuratedCollections, StateMap},
  props: {},
  data () {
    return {
      page: null,
      collections: [],
      collectionsContent: null,
      collectionsCount: 0,
      associations: [],
      associationsContent: null,
      showCollections: process.env.VUE_APP_SHOW_COLLECTIONS
    }
  },
  mounted() {
    Analytics.trackPage('Discover')

    if (this.$route.query.from) {
      switch (this.$route.query.from) {
        case 'landing':
          Analytics.trackEvent('Navigation', {category: 'Landing Promo', label: 'Discover'})
          break
        case 'header':
          Analytics.trackEvent('Navigation', {category: 'Header Navigation', label: 'Discover'})
          break
        case 'mobilemenu':
          Analytics.trackEvent('Navigation', {category: 'Mobile Menu', label: 'Discover'})
          break
      }
    }

    if (this.$auth.isAuthenticated) {
      this.$store.dispatch("getFavouriteProducts")
      this.$store.dispatch("getFavouriteWineries")
    }

    Kontent.getCollectionsCount().then((res) => {
      this.collectionsCount = res.data.items.length

      Kontent.getCollections().then((res) => {
        this.collections = res.data.items
        this.collectionsContent = res.data.modular_content
      })
    })

    Kontent.getAllAssociatedRegions().then((res) => {
      this.associations = res.data.items
      this.associationsContent = res.data.modular_content
    })

    if (this.$route.fullPath.indexOf('/spotlight') !== -1) {
      $('html, body').animate({scrollTop: $('.collections-section').offset().top - $('header .main').height() - 20}, 250)
    }

    Kontent.getItemByType('discover_page').then((res) => {
      this.page = res.data.items[0]

      setTimeout(function(){window.prerenderReady = true}, 1000)
    })
  }
}
</script>

<style scoped lang="scss">
.state-map {
  position: relative;
  z-index: 2;
}
.bg {
  position: absolute;
  z-index: 1;
  background-color: #121212;
  right: 0;
  left: 30%;
  top: 50px;
  bottom: 50px;
  display: none;
  @media(min-width: 768px)
  {
    display: block;
  }

  @media(min-width: 1200px)
  {
    top:100px;
    bottom: 100px;
  }

  @media(min-width: 1900px)
  {
    left: 40%;
  }
}
</style>
