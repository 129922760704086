<template>
  <div class="collection-tile w-full p-4">
    <router-link :to="`/discover/${stateSlug}/associated/${item.elements.slug.value}`" class="block text-white winery-inner bg-grey-100 pl-12 pb-12 lg:pl-20 lg:pb-20 group">
      <div class="image" v-if="item.elements.hero_image.value.length">
        <div class="image-container relative pb-9/16">
          <img :src="item.elements.hero_image.value[0].url" :alt="item.elements.name.value" class="block absolute left-0 top-0 w-full h-full object-cover max-w-none">
        </div>
      </div>
      <div class="title mt-12 text-18 font-bold pr-8">{{item.elements.name.value}}</div>
      <div class="description mt-4 text-14 pr-8 text-grey-500" v-html="item.elements.tag_line.value" v-if="showTagLine"></div>
      <div class="cta mt-12 text-14 flex flex-row items-center text-grey-400 uppercase group-hover:text-grey-500 transition-colors">
        View
        <img src="@/assets/icon-arrow.png" alt="" class="w-8 h-4 block ml-4 group-hover:ml-6 transition-all">
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'RegionalAssociationsTile',
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    },
    showTagLine: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    stateSlug () {
      switch (this.item.elements.state.value) {
        case 'New South Wales':
          return 'new-south-wales'
        case 'Queensland':
          return 'queensland'
        case 'South Australia':
          return 'south-australia'
        case 'Western Australia':
          return 'western-australia'
        case 'Tasmania':
          return 'tasmania'
        case 'Victoria':
          return 'victoria'
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
