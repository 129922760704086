<template>
  <div class="state-map">
    <div class="map relative">
      <div class="images absolute left-0 top-0 w-full h-full z-10">
        <img src="@/assets/map-australia-default.png" class="default block absolute left-0 top-0 w-full h-full" data-image="default"/>
        <img src="@/assets/map-australia-tas.png" class="block absolute left-0 top-0 w-full h-full opacity-0" data-image="tas"/>
        <img src="@/assets/map-australia-vic.png" class="block absolute left-0 top-0 w-full h-full opacity-0" data-image="vic"/>
        <img src="@/assets/map-australia-wa.png" class="block absolute left-0 top-0 w-full h-full opacity-0" data-image="wa"/>
        <img src="@/assets/map-australia-sa.png" class="block absolute left-0 top-0 w-full h-full opacity-0" data-image="sa"/>
        <img src="@/assets/map-australia-nsw.png" class="block absolute left-0 top-0 w-full h-full opacity-0" data-image="nsw"/>
        <img src="@/assets/map-australia-qld.png" class="block absolute left-0 top-0 w-full h-full opacity-0" data-image="qld"/>
      </div>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xml:space="preserve" class="relative z-20 opacity-0">
        <path data-image="wa" data-route="/discover/western-australia" class="cursor-pointer" d="M409,219l15,374l-94,59l-117,51l-57-34c0,0-59-170-59-173s5-108,5-108l142-71l31-60l70-65l34,8L409,219z"/>
        <path data-image="qld" data-route="/discover/queensland" class="cursor-pointer" d="M725,161l6,49l12-3l21,24l16,84l42,31l37,58l26,58l7-3h10l-7.8,41l4.8,39l-40,3l-14,13l-8-13l-25-4l-12,8l-149.9-12.3L653,469l-59-2l8-213l33.7,23H652l15-29l5-64l16-53l18.3-3L725,161z"/>
        <path data-image="sa" data-route="/discover/south-australia" class="cursor-pointer" d="M418.9,467H594l59,2l-2.9,64.7L644,643l-5,95l-24-15l-2-17l-9-24l-27,7l-23-2l-13-23l-12-14l-5-21l-17-19l-19.6-7.5L457,592l-33,1L418.9,467z"/>
        <path data-image="nsw" data-route="/discover/new-south-wales" class="cursor-pointer" d="M801,736l-34-23l-1-18h-18l-18-2l-6-6h-11.6v6H705l-20-23v-9.3l-10-3.7l-6,3l-5-13l-13-4h-7l6.1-109.3L800,546l13.8-7.7L837,542l8,13l14-13l40-3l-16,48l-21,50l-16,6l-19,34l-11,21l-10,21L801,736z"/>
        <path data-image="vic" data-route="/discover/victoria" class="cursor-pointer" d="M683,756l-44-18l5-95h7l13,4l5,13l6-3l10,3.7v9.3l20,23h7.4v-6H724l6,6l18,2h18l1,18l34,23l-12,7l-23,1l-32,25l-11-5l-22-17L683,756z"/>
        <path data-image="tas" data-route="/discover/tasmania" class="cursor-pointer" d="M679.7,769l9.3-6l10,13l9,12l19,12l24-6v-15.9l11.1-9.1l6.9,19l-3,46l-14,31l-24,9l-22-11l-9-24l-35,15l-28-6l-12-21l19-13l32-5l18,3l-5-25L679.7,769z"/>
      </svg>
    </div>
    <div class="state-list">
      <img src="@/assets/our-places.png" alt="" class="hidden md:block mb-8">
      <ul>
        <li>
          <router-link to="/discover/new-south-wales" data-image="nsw">
            <span class="label">New South Wales</span>
            <span class="abbr">NSW</span>
          </router-link>
        </li>
        <li>
          <router-link to="/discover/queensland" data-image="qld">
            <span class="label">Queensland</span>
            <span class="abbr">QLD</span>
          </router-link>
        </li>
        <li>
          <router-link to="/discover/south-australia" data-image="sa">
            <span class="label">South Australia</span>
            <span class="abbr">SA</span>
          </router-link>
        </li>
        <li>
          <router-link to="/discover/tasmania" data-image="tas">
            <span class="label">Tasmania</span>
            <span class="abbr">TAS</span>
          </router-link>
        </li>
        <li>
          <router-link to="/discover/victoria" data-image="vic">
            <span class="label">Victoria</span>
            <span class="abbr">VIC</span>
          </router-link>
        </li>
        <li>
          <router-link to="/discover/western-australia" data-image="wa">
            <span class="label">Western Australia</span>
            <span class="abbr">WA</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'StateMap',
  props: {},
  mounted() {
    const self = this
    $(this.$el).find('.map path').click(function(e){
      e.preventDefault();
      self.$router.push($(this).attr('data-route'))
    });
    $(this.$el).find('.map path').hover(function () {
      $('.map img[data-image="' + $(this).attr('data-image') + '"]').removeClass('opacity-0');
      $('.map img.default').addClass('opacity-0');
      $('.state-list a[data-image="' + $(this).attr('data-image') + '"]').addClass('active');
    }, function () {
      $('.map img.default').removeClass('opacity-0');
      $('.map img').not('[data-image="default"]').addClass('opacity-0');
      $('.state-list a[data-image="' + $(this).attr('data-image') + '"]').removeClass('active');
    })
    $(this.$el).find('.state-list a').hover(function () {
      $('.map img.default').addClass('opacity-0');
      $('.map img[data-image="' + $(this).attr('data-image') + '"]').removeClass('opacity-0');
    }, function () {
      $('.map img.default').removeClass('opacity-0');
      $('.map img').not('[data-image="default"]').addClass('opacity-0');
    })
  }
}
</script>

<style scoped lang="scss">

.state-map {
  @media(min-width: 768px)
  {
    box: horizontal middle reverse;

    .state-list {
      width: 100%;
    }

    .map {
      width: 60%;
      flex-shrink: 0;
    }
  }
}

.map {
  z-index: 5;
}

.state-list {
  position: relative;
  z-index: 5;
  li {
    a {
      display: block;
      position: relative;
      padding: 10px 0;
      border-bottom: 1px solid rgba(#FFF, 0.1);
      font-size: 20px;
      font-weight: bold;
      @media(min-width: 1024px)
      {
        font-size: 28px;
      }

      .abbr {
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        position: absolute;
        right: 0;
        bottom: 5px;

        @media(min-width: 1024px)
        {
          font-size: 13px;
        }
      }

      &:after {
        display: block;
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        transition: all 0.3s;
        background-color: #E8490F;
        opacity: 0;
        left: 0;
      }

      &:hover, &.active {
        &:after {
          height: 4px;
          opacity: 1;
        }

        .abbr {
          color: #E8490F;
        }
      }
    }
  }
}
</style>
